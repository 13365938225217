
export default {
  name: 'RadioSelect',
  props: {
    valueKey: {
      type: String,
      default: 'value',
    },
    selected: {
      type: Object,
      default: null,
    },
    labelKey: {
      type: String,
      default: 'label',
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    slots() {
      return this.$scopedSlots
    },
  },
  methods: {
    itemClasses(item) {
      return item === this.selected
        ? 'border shadow-border-xs-secondary border-secondary bg-info-light'
        : 'border border-gray-dark'
    },
  },
}
